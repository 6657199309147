import React from "react";
import { Link } from "gatsby";
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faEnvelope, faMobile, faCog, faICursor, faCalendar } from '@fortawesome/free-solid-svg-icons'

interface Feature {
  icon: IconDefinition
  title: string
  href?: string
  description: string
}

const feature: Feature[] = [
  {
    icon: faEnvelope,
    title: 'Email marketing and 1-to-1 personalisation',
    // href: '/tools/email-ab-test-calculator/',
    description: `
    Stop holding data with a limited lifespan in your ESP. Want to provide unique survey URLs to your recipients? Host the links on a Google Sheet and identify your recipients with a simple API call.
    `
  },
  {
    icon: faBook,
    title: 'A content management system',
    // href: '/tools/statistical-significance-calculator/',
    description: `
    CMS offerings have improved in leaps and bounds, but sometimes simple is best. To update headlines, opening times, menus and more, nothing is simpler than a spreadsheet.
    `
  },
  {
    icon: faCog,
    title: 'A recommendation engine',
    // href: '/tools/css-inliner/',
    description: `
    Do you keep a blog? Surface content recommendations your readers will love.
    `
  },
  {
    icon: faCalendar,
    title: 'Timetables and rosters',
    // href: '/tools/css-inliner/',
    description: `
    Keep your customers and team in the loop about what's happening when.
    `
  }
]

const Features: React.FunctionComponent = () => (
  <div className="row pb-5">
    {feature.map(({ icon, title, href, description }) => (
      <div className="col-md-6 py-3" key={href}>
        <Link to={href} className="text-decoration-none">
          <div className="card h-100">
            <div className="card-body">
              <div className="pb-2 text-center">
                <FontAwesomeIcon icon={icon} />
              </div>
              <h5 className="card-title text-dark text-center">
                {title}
              </h5>
              <p className="card-text text-dark">
                {description}
              </p>
              {/* <p className="text-center text-primary text-bold">
                Use it
              </p> */}
            </div>
          </div>
        </Link>
      </div>
    ))}
  </div>
)

export default Features