import React from 'react'
import Navbar from './Navbar'
import SEO from '../../SEO'
import Layout from '../Layout'
import { VerticalSpace } from '../../VerticalSpace'
import WordCycler from '../../WordCycler'
import { graphql, useStaticQuery } from 'gatsby'

interface CellDBLayoutProps {

}

const description = "Connect your Google Sheets to anything: create REST APIs with just a few clicks. "

interface QueryData {
  image: {
    publicURL: string
  },
}

const query = graphql`
query {
  image: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "marketing/CellDBGraphic.png" }) {
    publicURL
  }
}
`

const CellDBLayout: React.FunctionComponent<CellDBLayoutProps> = ({ children }) => {
  const data: QueryData = useStaticQuery(query)

  return (
    <React.Fragment>
      {/* <Navbar /> */}

      <SEO title="Turn your spreadsheets to REST APIs" description={description} />

      <Layout title="Connect your spreadsheets to anything" >
        <VerticalSpace height={50} />

        <div className="pt-15 container text-left">
          <div className="container text-dark">

            <div className="row">
              <div className="col-md">
                <h2 className="mb-3 text-secondary">
                  Cell DB connects your sheets to <span> <WordCycler words={["websites", "apps", "any API", "anything"]} interval={1500} />
                  </span>
                </h2>

                <p>
                  Build production-ready websites or apps, and rapidly deploy marketing at scale. CellDB turns your spreadsheets into REST APIs so you can build whatever you want in a matter of minutes. The possibilities are endless.
                </p>

                <VerticalSpace height={25} />

                <h2 className="mb-3 text-secondary">Getting started is simple</h2>
                <ol>
                  <li>Create an account on CellDB</li>
                  <li>Create an API secret key</li>
                  <li>Abracadabra, now you can integrate with anything</li>
                </ol>

                <a href="//celldb.creativeilk.com" target="_blank" className="btn btn-primary my-3">
                  Sign Up
                </a>
              </div>

              <div className="col-md text-center">
                <img src={data.image.publicURL} />
              </div>
            </div>
          </div>
        </div>

        <div className="container py-3 text-dark">
          {children}
        </div>
      </ Layout >

    </React.Fragment>
  )
}

export default CellDBLayout
