import React from 'react'
import Features from '../../../components/CellDB/Features'
import CellDBLayout from "../../../components/Layouts/CellDBLayout"
import { VerticalSpace } from '../../../components/VerticalSpace'


const IndexPage: React.FunctionComponent = () => {
  return (
    <CellDBLayout>
      <VerticalSpace height={50} />
      <h2 className="mb-3 text-secondary">How can I use a REST API?</h2>
      <Features />
      <VerticalSpace height={50} />
    </CellDBLayout>
  )
}

export default IndexPage
